// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/Users/konstantin/work/src/monsterparkbox.com/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("/Users/konstantin/work/src/monsterparkbox.com/src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-brachiosaurus-tsx": () => import("/Users/konstantin/work/src/monsterparkbox.com/src/pages/brachiosaurus.tsx" /* webpackChunkName: "component---src-pages-brachiosaurus-tsx" */),
  "component---src-pages-index-tsx": () => import("/Users/konstantin/work/src/monsterparkbox.com/src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-raptor-tsx": () => import("/Users/konstantin/work/src/monsterparkbox.com/src/pages/raptor.tsx" /* webpackChunkName: "component---src-pages-raptor-tsx" */),
  "component---src-pages-stegosaurus-tsx": () => import("/Users/konstantin/work/src/monsterparkbox.com/src/pages/stegosaurus.tsx" /* webpackChunkName: "component---src-pages-stegosaurus-tsx" */),
  "component---src-pages-trex-tsx": () => import("/Users/konstantin/work/src/monsterparkbox.com/src/pages/trex.tsx" /* webpackChunkName: "component---src-pages-trex-tsx" */),
  "component---src-pages-triceratops-tsx": () => import("/Users/konstantin/work/src/monsterparkbox.com/src/pages/triceratops.tsx" /* webpackChunkName: "component---src-pages-triceratops-tsx" */)
}

exports.data = () => import("/Users/konstantin/work/src/monsterparkbox.com/.cache/data.json")

